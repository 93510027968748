import { makeStyles } from '@material-ui/styles';
import { media } from '.';
import { phonesOnly, tabletsAndBelow, tabletsOnly } from './responsive';

/**
 *
 */
export const useStyles = makeStyles({
  root: {
    '& > $button': {
      display: 'block'
    },

    '& > $playlist': {
      display: 'none'
    },

    '&.open': {
      '& > $button': {
        display: 'none'
      },

      '& > $playlist': {
        display: 'flex'
      },
    },
  },

  button: {
    position: 'fixed',
    bottom: 0,
    left: 0,
    margin: '0 0 2em 2em',
    zIndex: 1000,

    '&.showWaveform': {
      '& .ui.music.icon': {
        display: 'none'
      },

      '& .waveform': {
        display: 'block'
      }
    },

    '& .waveform': {
      display: 'none'
    }
  },

  buttons: {
    [media(tabletsOnly)]: {
      '& .ui.icon': {
        fontSize: '1.25em',

        '&:not(:last-child)': {
          marginRight: '1rem !important',
        },
      }
    }
  },

  playlist: {
    borderRadius: '0.28571429rem',
    bottom: '2em',
    boxShadow: '0 0 5rem rgba(0,0,0,0.75)',
    left: '2em',
    maxHeight: 'calc(100% - 4em)',
    overflow: 'hidden',
    position: 'fixed',
    width: '65%',
    zIndex: 1000,

    [media(tabletsOnly)]: {
      right: '2em',
      width: 'auto',
    },

    [media(phonesOnly)]: {
      bottom: 0,
      left: 0,
      right: 0,
      width: 'auto',
    },

    '&.minimized': {
      right: 'auto',
      width: 'auto',
    },

    '& > .ui.segments': {
      background: 'white',
      display: 'flex',
      flexGrow: 1,
      overflow: 'hidden'
    },

    '& tr.current': {
      color: '#c60a17',
      fontWeight: 'bold'
    },
  },

  songListContainer: {
    overflow: 'auto',
    paddingTop: '0 !important',

    [media(tabletsAndBelow)]: {
      '& tbody td': {
        paddingBottom: '1.5em !important',
        paddingTop: '1.5em !important',
      }
    },

    '& thead th': {
      background: 'rgba(255, 255, 255, 0.9) !important',
      borderRadius: '0 !important',
      position: 'sticky',
      paddingTop: '2em !important',
      top: 0,

      // Solves an issue with the remove icon for each table row appearing
      // on top of the header.
      zIndex: 1,
    }
  },

  titleBar: {
    [media(phonesOnly)]: {
      paddingLeft: '1em !important',
      paddingRight: '1em !important',

      '& > .grid': {
        flexDirection: 'column-reverse',
      }
    }
  },
}, {
  classNamePrefix: 'AudioPlayer'
});
