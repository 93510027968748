/**
 *
 */
export function noop(): (...args: unknown[]) => void;

/**
 *
 * @param ret
 */
export function noop<R>(ret: R): (...args: unknown[]) => R;

export function noop<R>(ret?: R) {
  return (): R | void => ret;
}

/**
 *
 */
export function asyncNoop<_R>(): (...args: unknown[]) => Promise<void>;

/**
 *
 * @param ret
 */
export function asyncNoop<R>(ret: R): (...args: unknown[]) => Promise<R>;

export function asyncNoop<R>(ret?: R) {
  return (): Promise<void | R> =>
    new Promise(resolve => ret ? resolve(ret) : resolve());
}
