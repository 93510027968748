import { useMemo } from 'use-memo-one';
import createPersistedState from 'use-persisted-state';

/**
 *
 */
export enum RepeatMode {
  None,
  All,
  One,
}

/**
 *
 */
export interface PlayerConfig {
  readonly shuffled: boolean;
  readonly repeat: RepeatMode;
  readonly volume: number;
  readonly currentTime?: number;
}

/**
 *
 */
export const usePlayerConfigState = createPersistedState('playerConfig');

 /**
  *
  */
export const usePlayerConfig = () => {
  const [ config, setConfig ] = usePlayerConfigState<PlayerConfig>({
    shuffled: false,
    repeat: RepeatMode.None,
    volume: 1
  });

  return useMemo(() => ({
    /**
    *
    */
    set shuffled(shuffled: PlayerConfig['shuffled']) {
      setConfig({ ...config, shuffled });
    },

    /**
    *
    */
    get shuffled() { return config.shuffled; },

    /**
    *
    */
    set repeat(repeat: PlayerConfig['repeat']) {
      setConfig({ ...config, repeat });
    },

    /**
    *
    */
    get repeat() { return config.repeat; },

    /**
    *
    */
    set volume(volume: PlayerConfig['volume']) {
      setConfig({ ...config, volume });
    },

    /**
    *
    */
    get volume() { return config.volume; },

    /**
     *
     */
    set currentTime(currentTime: PlayerConfig['currentTime']) {
      setConfig({ ...config, currentTime });
    },

    /**
     *
     */
    get currentTime() { return config.currentTime; },
  }), [config]);
};
