import React, { DetailedHTMLProps, StyleHTMLAttributes } from 'react';
import { CSSProperties, makeStyles, StyleRules } from '@material-ui/styles';
import classNames from 'classnames';
import { fromPairs, times } from 'lodash';

/**
 *
 */
export interface WaveformLoaderPropTypes {
  readonly className?: string;
  readonly style?: CSSProperties;
}

/**
 *
 */
const useStyles = makeStyles({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
  },

  wave: {
    fill: '#fff',
  },

  line: {
    animation: '$pulse 1s infinite',
  },

  '@keyframes pulse': {
    '0%': {
      transform: 'scaleY(1)',
      transformOrigin: '50% 50%',
    },

    '50%': {
      transform: 'scaleY(.7)',
      transformOrigin: '50% 50%',
    },

    '100%': {
      transform: 'scaleY(1)',
      transformOrigin: '50% 50%',
    }
  }
}, {
  classNamePrefix: 'WaveformLoader'
});

/**
 *
 * @param props
 */
export const WaveformLoader: React.FC<WaveformLoaderPropTypes> = props => {
  const styles = useStyles();

  return <div style={props.style} className={classNames(styles.root, props.className)}>
    <svg className={styles.wave} data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 38.05">
      <title>Audio Wave</title>
      <path className={styles.line} style={{animationDelay: `${1 * .15}s`}} data-name="Line 1" d="M0.91,15L0.78,15A1,1,0,0,0,0,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H0.91Z"/>
      <path className={styles.line} style={{animationDelay: `${2 * .15}s`}} data-name="Line 2" d="M6.91,9L6.78,9A1,1,0,0,0,6,10V28a1,1,0,1,0,2,0s0,0,0,0V10A1,1,0,0,0,7,9H6.91Z"/>
      <path className={styles.line} style={{animationDelay: `${3 * .15}s`}} data-name="Line 3" d="M12.91,0L12.78,0A1,1,0,0,0,12,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H12.91Z"/>
      <path className={styles.line} style={{animationDelay: `${4 * .15}s`}} data-name="Line 4" d="M18.91,10l-0.12,0A1,1,0,0,0,18,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H18.91Z"/>
      <path className={styles.line} style={{animationDelay: `${5 * .15}s`}} data-name="Line 5" d="M24.91,15l-0.12,0A1,1,0,0,0,24,16v6a1,1,0,0,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H24.91Z"/>
      <path className={styles.line} style={{animationDelay: `${6 * .15}s`}} data-name="Line 6" d="M30.91,10l-0.12,0A1,1,0,0,0,30,11V27a1,1,0,1,0,2,0s0,0,0,0V11a1,1,0,0,0-1-1H30.91Z"/>
      <path className={styles.line} style={{animationDelay: `${7 * .15}s`}} data-name="Line 7" d="M36.91,0L36.78,0A1,1,0,0,0,36,1V37a1,1,0,1,0,2,0s0,0,0,0V1a1,1,0,0,0-1-1H36.91Z"/>
      <path className={styles.line} style={{animationDelay: `${8 * .15}s`}} data-name="Line 8" d="M42.91,9L42.78,9A1,1,0,0,0,42,10V28a1,1,0,1,0,2,0s0,0,0,0V10a1,1,0,0,0-1-1H42.91Z"/>
      <path className={styles.line} style={{animationDelay: `${9 * .15}s`}} data-name="Line 9" d="M48.91,15l-0.12,0A1,1,0,0,0,48,16v6a1,1,0,1,0,2,0s0,0,0,0V16a1,1,0,0,0-1-1H48.91Z"/>
    </svg>
  </div>;
};
