import { Song } from 'common/models/song';
import { knuthShuffle } from 'knuth-shuffle';
import { filter, first } from 'lodash';
import { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useMemo } from 'use-memo-one';
import createPersistedState from 'use-persisted-state';
import { RepeatMode, usePlayerConfig } from './use-player-config';
import useSongs, { SongManager } from './use-songs';

/**
 *
 */
interface SongQueueManager extends SongManager {
  readonly queue: readonly Song[];
  readonly setCurrentSong: Dispatch<SetStateAction<Song | undefined>>;
  readonly currentSong?: Song;
  readonly previousSong?: Song;
  readonly nextSong?: Song;
  readonly currentSongIndex?: number;

}

const useCachedQueueState = createPersistedState('queue');

/**
 *
 * @returns
 */
export const useSongsQueue = (): SongQueueManager => {
  const { songs, songHandles, lookupSongs, songsLoading, hasSong, ...songsManager } = useSongs();
  const [ cachedQueue, setCachedQueue ] = useCachedQueueState<readonly string[]>([]);
  const [ initialized, setInitialized ] = useState(false);

  const playerConfig = usePlayerConfig();
  const wasShuffled = useRef(playerConfig.shuffled);

  // Memoize the queue from the cached handles, which only change on page
  // load or when the list of songs changes.
  const queue = useMemo<readonly Song[]>(
    () => lookupSongs(...cachedQueue),
    [lookupSongs, ...cachedQueue]
  );

  const [ currentSong, setCurrentSong ] = useState(first(queue));

  // Generate a queue of songs for the player, shuffling the queue if necessary.
  // This should update the chache when songs are removed, even server-side in
  // the future.


    // Only shuffle the songs if the shuffled option is true and this isn't
    // the first time loading the cached data, and we aren't loading songs.

  useEffect(() => {
    let newCachedQueue: readonly string[];

    if (!wasShuffled.current && playerConfig.shuffled && !songsLoading && initialized) {
      newCachedQueue = currentSong
        ? [
          currentSong.attributes.handle,
          ...knuthShuffle(filter(songHandles, handle => handle !== currentSong.attributes.handle))
        ]
        : knuthShuffle([...songHandles]);
    } else {
      newCachedQueue = songHandles;
    }

    wasShuffled.current = playerConfig.shuffled;

    setCachedQueue(newCachedQueue);
  }, [songHandles, playerConfig.shuffled]);

  useEffect(() => setInitialized(true), []);

  // Generate the the index of the current song in the `queue`
  const currentSongIndex = useMemo(
    () => currentSong ? queue.indexOf(currentSong) : -1,
    [queue, currentSong]
  );

  // Generate the previous/next song in the player. This will take into account
  // whether or not the player is currently shuffled because it uses the
  // `queue`.
  const [ previousSong, nextSong ] = useMemo(
    () => {
      switch (playerConfig.repeat) {
        case RepeatMode.All:
          return [
            queue[(currentSongIndex || queue.length) - 1],
            queue[(currentSongIndex + 1) % queue.length]
          ];

        case RepeatMode.One:
          return [
            queue[currentSongIndex],
            queue[currentSongIndex]
          ];

        default:
          return [
            currentSongIndex ? queue[currentSongIndex - 1] : undefined,
            queue[currentSongIndex + 1]
          ];
      }
    },
    [queue, currentSongIndex, playerConfig.repeat]
  );

  // Make sure the current song is still available, or progress if it was
  // removed.
  useEffect(() => {
    if (!currentSong || !hasSong(currentSong)) {
      setCurrentSong(nextSong);
    }
  }, [queue, hasSong, currentSong, nextSong]);

  return {
    ...songsManager,
    lookupSongs,
    hasSong,
    songs,
    songHandles,
    queue,
    currentSong,
    setCurrentSong,
    currentSongIndex,
    previousSong,
    nextSong,
    songsLoading,
  };
};
