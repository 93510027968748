/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
import 'core-js';
import 'regenerator-runtime/runtime';

import ReactRailsUJS from 'react_ujs';
import React from 'react';
import Turbolinks from 'turbolinks';
import { extend } from 'lodash';
import { SidebarSearch } from 'application/SidebarSearch';
import { ScriptureSlider } from 'application/ScriptureSlider';
import { Search } from 'application/Search';
import { enableMapSet } from 'immer';
import ReactDOM from 'react-dom';
import { NavProgress } from 'application/NavProgress';

enableMapSet();

extend(window, {
  Search,
  SidebarSearch,
  ScriptureSlider,
  NavProgress
});

Turbolinks.start();
ReactRailsUJS.detectEvents();

/**
 *
 */
const setupAudioPlayer = async () => {
  const { Root } = await import('audio-player/components/Root');

  document.addEventListener('DOMContentLoaded', () => {
    ReactDOM.render(
      <Root />,
      document.body.querySelector('#AudioPlayerContainer'),
    );
  });
};

setupAudioPlayer();

export {};
