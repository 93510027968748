import useSongs from 'audio-player/hooks/use-songs';
import classNames from 'classnames';
import React, { useCallback, useContext } from 'react';
import { createPortal } from 'react-dom';
import { AudioPlayerConfigContext } from './AudioPlayer';

/**
 * [AddButton description]
 * @type {[type]}
 */
export interface PropTypes {
  readonly songHandle: string;
  readonly element: Element;
  readonly iconOnly?: boolean;
  readonly inline?: boolean;
}

export const AddButton: React.FC<PropTypes> = props => {
  const { addSong, hasSong } = useSongs();
  const [ , updateConfig ] = useContext(AudioPlayerConfigContext);

  /**
   *
   */
  const onClick = useCallback(() => {
    addSong(props.songHandle);
    updateConfig({open: true, minimized: false});
  }, [addSong, props.songHandle]);

  /**
   *
   * @returns
   */
  const renderAddButton = () =>
    <button
      className={classNames(
        'ui red icon button',
        {fluid: !props.inline && !props.iconOnly}
      )}
      onClick={onClick}
    >
      <i className={classNames('ui music icon', {red: props.iconOnly})} />
      {!props.iconOnly && 'Add to Playlist'}
    </button>;

  /**
   *
   * @returns
   */
  const renderAlreadyAddedButton = () =>
    <button
      className={classNames(
        'ui disabled red icon button',
        {fluid: !props.inline && !props.iconOnly}
      )}
      disabled={true}
    >
      <i className={classNames('ui check icon', {red: props.iconOnly})} />
      {!props.iconOnly && 'Song Added to Playlist'}
    </button>;

  /**
   *
   * @returns
   */
  const renderButton = () =>
    hasSong(props.songHandle) ?
      renderAlreadyAddedButton()
      :
      renderAddButton();

  return createPortal(renderButton(), props.element);
};
