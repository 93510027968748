import { useCallback, useEffect, useState } from 'react';

/**
 *
 */
export interface EventManager<T, E extends EventTarget> {
  /**
   *
   * @param data
   * @param target
   */
  trigger(data?: T, target?: E): void;

  readonly count: number;
}

/**
 *
 * @param eventName
 * @param target
 */
export default function useEvent<T, E extends EventTarget = Document>(
  eventName: string,
  target?: EventTarget,
  handler?: (data: T, event?: CustomEvent<T>) => void,
  deps?: any[]
): EventManager<T, E>;

/**
 *
 * @param eventName
 * @param target
 * @param handler
 * @param cleanup
 * @param deps
 */
export default function useEvent<T, E extends EventTarget = Document>(
  eventName: string,
  target?: EventTarget,
  handler?: (data: T, event?: CustomEvent<T>) => void,
  cleanup?: () => void,
  deps?: any[]
): EventManager<T, E>;

/**
 *
 * @param eventName
 * @param target
 * @param handler
 * @param cleanupOrDeps
 * @param deps
 * @returns
 */
export default function useEvent<T, E extends EventTarget = Document>(
  eventName: string,
  target: EventTarget = document,
  handler?: (data: T, event?: CustomEvent<T>) => void,
  cleanupOrDeps?: (() => void) | any[],
  deps?: any[]
) {
  const cleanup = typeof cleanupOrDeps === 'function' ? cleanupOrDeps : () => {};
  deps = typeof cleanupOrDeps === 'function' ? [] : cleanupOrDeps || [];

  const [ count, setCount ] = useState(0);

  useEffect(() => {
    const internalHandler = (e: CustomEvent<T>) => {
      console.log(e.type);
      setCount(count + 1);
      handler?.(e.detail, e);
    };

    target.addEventListener(eventName, internalHandler);

    return () => {
      cleanup();
      target.removeEventListener(eventName, internalHandler);
    };
  }, [count, ...deps]);

  /**
   * [return description]
   * @param  data   [description]
   * @param  target [description]
   * @return        [description]
   */
  const trigger = useCallback((data?: T, target: EventTarget = document) => {
    target.dispatchEvent(new CustomEvent(eventName, {detail: data}));
  }, deps);

  return { trigger, count };
}
