import classNames from 'classnames';
import { pick } from 'lodash';
import React, { useEffect, useState } from 'react';

/**
 *
 */
export interface SliderPropTypes {
  readonly initialValue?: number;
  readonly max?: number;
  readonly min?: number;
  readonly step?: number;
  readonly small?: boolean;
  readonly smooth?: boolean;
  readonly color?: string;
  readonly className?: string;
  readonly onChange?: (event: CustomEvent<number>) => void;
}

/**
 *
 */
export const Slider: React.FC<SliderPropTypes> = props => {
  const [ ref, setRef ] = useState<HTMLDivElement | null>(null);

  /**
   *
   */
  useEffect(() => {
    if (!ref) return;

    const $slider = jQuery(ref).slider({
      ...pick(props, ['max', 'min', 'step', 'smooth']),

      // Fixes an infinite loop bug on resize with step: 0
      autoAdjustLabels: false,

      /**
       *
       */
      onChange() {
        props?.onChange?.(new CustomEvent('onchange', {
          detail: $slider.slider('get value')
        }));
      },
    });

    $slider.slider('set value', props.initialValue);

    return () => $slider.slider('destroy');
  }, [ref]);

  return <div
    ref={setRef}
    className={classNames(
      'ui',
      {small: props.small},
      props.color,
      props.className,
      'slider'
    )}
  ></div>;
};
