import { getSongs } from 'common/api/songs';
import { noop } from 'common/utils/functions';
import { Song } from 'common/models/song';
import { diff, patch } from 'jsondiffpatch';
import React, { createContext, Dispatch, SetStateAction, useRef } from 'react';
import useSWR from 'swr';
import { useMemo } from 'use-memo-one';
import createPersistedState from 'use-persisted-state';

/**
 *
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface SongsProviderPropTypes {

}

const useRawSongsState = createPersistedState('songs');


const initialData: Song[] = [];

export const SongsContext = createContext<{
  readonly songs: readonly Song[];
  readonly songHandles: readonly string[],
  readonly setSongHandles: Dispatch<SetStateAction<string[]>>;
  readonly songsLoading: boolean;
  readonly error?: unknown;
}>({
  songs: initialData,
  songHandles: [],
  setSongHandles: noop(),
  songsLoading: false
});

/**
 *
 * @param props
 */
export const SongsProvider: React.FC<SongsProviderPropTypes> = props => {
  const [ songHandles, setSongHandles ] = useRawSongsState<readonly string[]>([]);
  const cacheRef = useRef(initialData);

  // useSWR returns undefined for songs when revalidating... We need to do
  // some custom caching...
  const { data, isValidating: songsLoading, error } = useSWR<Song[]>(
    [...songHandles],
    async (...handles: string[]) => {
      const songs = await getSongs(handles);
      const current = [...cacheRef.current];

      const delta = diff(current, songs);

      return delta ? patch(current, delta) : songs;
    },
    {
      initialData,
      revalidateOnMount: true,
      revalidateOnFocus: false,
      refreshWhenOffline: false,
      refreshWhenHidden: false,
      refreshInterval: 0,
    },
  );

  // `current` can change from within the fetcher to force an update.
  cacheRef.current = (data !== initialData && data) ? data : cacheRef.current;
  const songs = cacheRef.current;

  const value = useMemo(() => ({
    setSongHandles,
    songHandles,
    error,
    songs,
    songsLoading,
  }), [songHandles, error, songs, songsLoading]);

  return <SongsContext.Provider value={value}>
    {props.children}
  </SongsContext.Provider>;
};
