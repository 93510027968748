import { ApiResponse } from 'common/api/base';
import { Song } from 'common/models/song';
import { Search as UISearch, SearchProps, SearchResultProps } from 'semantic-ui-react';
import React, { KeyboardEvent, MouseEvent, useCallback, useState } from 'react';
import classNames from 'classnames';
import { debounce, some } from 'lodash';
import { stringify } from 'qs';
import { useMemo } from 'use-memo-one';
import { makeStyles } from '@material-ui/styles';




export interface SearchPropTypes {
  readonly endpoint: string;
  readonly limit: number;
  readonly transparent?: boolean;
  readonly placeholder?: string;
  readonly value?: string;
  readonly onSearching?: (searching: boolean) => void;
  readonly onResultsOpen?: () => void;
  readonly onResults?: (response: SearchResponse, queryUrl: string) => boolean | void;
  readonly onResultsClose?: () => void;
  readonly onBlur?: () => void;
  readonly onFocus?: () => void;
}

export type SearchResponse = ApiResponse & { data: readonly Song[] };

/**
 *
 */
const useStyles = makeStyles({
  root: {
    '&.ui.search': {
      '& > .results .result .image': {
        width: 'auto'
      },

      '&.focus > $input': {
        transition: 'width 0.5s',
        width: '100%',
      }
    }
  },

  input: {
    transition: 'width 0.5s',
    width: '50%',
  },

  seeMore: {
    textAlign: 'center'
  }
}, {
  classNamePrefix: 'Search'
});

/**
 *
 * @param props
 * @returns
 */
export const Search: React.FC<SearchPropTypes> = props => {
  const [ response, setResponse ] = useState<SearchResponse | null>(null);
  const [ loading, setLoading ] = useState(false);
  const [ currentQuery, setCurrentQuery ] = useState('');
  const [ valueOverride, setValueOverride ] = useState<string | undefined>(undefined);

  /**
   *
   */
  const makeUrl = useCallback((q: string, params: Record<string, unknown> = {}) => {
    const url = new URL(window.location.origin);
    url.pathname = props.endpoint;
    url.search = stringify({ ...params, q });

    return url.href;
  }, [props.endpoint, props.limit]);

  const styles = useStyles();

  /**
   *
   */
  const searchSongs = useCallback(async (q: string) => {
    setCurrentQuery(q);

    const response = await fetch(makeUrl(q, {per: props.limit}), {
      headers: {
        accept: 'application/json'
      }
    });

    const newResults = await response.json();

    if (props.onResults?.(newResults, makeUrl(q)) !== false) setResponse(newResults);

    props.onSearching?.(false);
    setLoading(false);
  }, [props.endpoint, props.limit]);

  /**
   *
   */
  const search = useMemo(() =>
    debounce(searchSongs, 200),
    [searchSongs]
  );

  /**
   *
   */
  const startSearch = useCallback((e: MouseEvent<HTMLElement>, data: SearchProps) => {
    if (data.value) {
      props.onSearching?.(true);
      setLoading(true);
      search(data.value);
    } else {
      props.onSearching?.(false);
      setLoading(false);
    }
  }, [props.onSearching]);

  /**
   *
   */
  const blur = useCallback(() => {
    setValueOverride('');
    props.onBlur?.();
  }, [props.onBlur]);

  /**
   *
   */
  const focus = useCallback(() => {
    setValueOverride(undefined);
    props.onFocus?.();
  }, [props.onFocus]);

  /**
   *
   */
  const gotoMoreResults = useCallback(() =>
    location.href = makeUrl(currentQuery)
  , [currentQuery]);

  /**
   *
   */
  const onKeyPress = useCallback((e: KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') gotoMoreResults();
  }, [gotoMoreResults]);

  const results: readonly SearchResultProps[] = useMemo(() =>
    // Map song attributes to search result properties
    (response?.data || []).map(song => ({
      id: song.attributes.handle,
      title: song.attributes.title,
      description: `${song.attributes.year}`,
      image: song.relationships.image.links.file_thumb,
      onClick: () => location.href = song.links.self,
    })),
    [response]
  );

  return <UISearch
    showNoResults={!props.onResults && !loading}
    fluid
    input={{
      icon: 'search',
      placeholder: 'Search',
      fluid: false,
      className: styles.input,
      onKeyPress
    }}
    loading={loading}
    className={classNames(styles.root, {inverted: props.transparent})}
    onSearchChange={startSearch}
    onBlur={blur}
    onFocus={focus}
    value={valueOverride === undefined ? props.value : valueOverride}
    results={some(results) && response?.meta.pagination?.last_page === false
      ? [...results, {
        className: styles.seeMore,
        title: 'See more results...',
        onClick: gotoMoreResults
      }]
      : results
    }
  >
  </UISearch>;
};
