import { ary, padStart, partialRight, toString } from 'lodash';

/**
 *
 * @param seconds
 * @param exponent
 * @returns
 */
const parts = (seconds: number, exponent = 1): number[] =>
  exponent ? [
    Math.floor(seconds / Math.pow(60, exponent)),
    ...parts(seconds % Math.pow(60, exponent), exponent - 1)
  ] : [seconds];

/**
 *
 */
const zeroPadded = ary(partialRight(padStart, 2, '0'), 1);

/**
 *
 * @param seconds
 * @param exponent
 */
export const formatTime = (seconds: number, exponent = 1): string =>
  parts(seconds, exponent).join(':');

/**
 *
 * @param seconds
 * @param exponent
 * @returns
 */
export const formatPlayTime = (seconds: number, exponent = 1): string =>
  parts(seconds, exponent).map(toString).map(zeroPadded).join(':');
