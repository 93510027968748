import useEvent from 'audio-player/hooks/use-event';
import { formatPlayTime } from 'audio-player/lib/time';
import classNames from 'classnames';
import { identity, isEmpty, orderBy, partial } from 'lodash';
import React, { createContext, useCallback, useState } from 'react';
import { useMemo } from 'use-memo-one';
import { useEffect } from 'react';
import MediaQuery, { useMediaQuery } from 'react-responsive';
import createPersistedState from 'use-persisted-state';
import Player from './Player';
import { useSongsQueue } from 'audio-player/hooks/use-songs-queue';
import { usePlaylists } from 'audio-player/hooks/use-playlists';
import { useStyles } from './styles/AudioPlayer';
import { useCommonStyles } from './styles/Common';
import { AddButton } from './AddButton';
import { phonesOnly, tabletsAndUp } from './styles/responsive';
import { WaveformLoader } from './util/WaveformLoader';
import { ResourceLinks } from '../../common/components/ResourceLinks';
import { Contribution } from 'common/models/contribution';




export const useAudioPlayerConfigState = createPersistedState('audioPlayerConfig');

/**
 *
 */
export interface AudioPlayerConfig {
  readonly minimized: boolean;

  readonly open: boolean;

  readonly sortBy?: [string, 'asc' | 'desc'];
}

const sortDirections = ['asc', 'desc'] as const;

export const AudioPlayerConfigContext = createContext<[
  AudioPlayerConfig,
  (config: Partial<AudioPlayerConfig>) => void
]>([
  {
    minimized: false,
    open: false,
    sortBy: ['attributes.title', 'asc'],
  },
  identity
]);

/**
 * [AudioPlayer description]
 * @type {[type]}
 */
const AudioPlayer: React.FC = _props => {
  // const [ showPlaylists, setShowPlaylists ] = useState(false);
  const [ showWaveform, setShowWaveform ] = useState(false);
  const [ addButtons, setAddButtons ] = useState<HTMLElement[]>([]);
  const [ config, setConfig ] = useAudioPlayerConfigState<AudioPlayerConfig>({
    minimized: false,
    open: false
  });

  const styles = useStyles();
  const commonStyles = useCommonStyles();

  const {
    removeSong,
    songs,
    queue,
    nextSong,
    previousSong,
    currentSong,
    setCurrentSong,
    songsLoading,
  } = useSongsQueue();

  const {
    playlists
  } = usePlaylists();

  // Track turbolinks load events
  const turbolinksLoad = useEvent('turbolinks:load', document);

  /**
   *
   */
  useEffect(() => {
    const addButtons = document.querySelectorAll<HTMLElement>(
      '[data-add-to-audio-player][data-song-handle]'
    );

    setAddButtons(Array.from(addButtons));
  }, [turbolinksLoad.count, songs]);

  const fullScreen = useMediaQuery(phonesOnly);

  const sortedSongs = useMemo(() =>
    config.sortBy ? orderBy(songs, ...config.sortBy) : songs
  , [songs, config.sortBy]);

  /**
   *
   */
  useEffect(() => {
    document.querySelector('.pusher')
      ?.classList
      .toggle('dimmed', fullScreen && config.open && !config.minimized);
  }, [fullScreen, config.minimized, config.open]);

  /**
   *
   */
  const plyrOptions = useMemo(() => ({
    invertTime: false
  }), []);

  /**
   *
   */
  const goToNextSong = useCallback(() => {
    setCurrentSong(nextSong);
    return nextSong;
  }, [nextSong]);

  /**
   *
   */
  const goToPreviousSong = useCallback(() => {
    setCurrentSong(previousSong);
    return previousSong;
  }, [previousSong]);

  /**
   *
   */
  const minimize = useCallback(() => {
    setConfig({...config, minimized: true});
  }, [config]);

  /**
   *
   */
  const maximize = useCallback(() => {
    setConfig({...config, minimized: false});
  }, [config]);

  /**
   *
   */
  const close = useCallback(() => {
    setConfig({...config, open: false});
  }, [config]);

  /**
   *
   */
  const open = useCallback(() => {
    setConfig({...config, open: true});
  }, [config]);

  /**
   *
   */
  const sortSongs = useCallback((newProperty: string) => {
    if (!config.sortBy) {
      setConfig({...config, sortBy: [newProperty, 'asc'] });
      return;
    }

    const [ property, direction ] = config.sortBy;

    setConfig({
      ...config,
      sortBy: [
        newProperty,
        property === newProperty
          ? sortDirections[(sortDirections.indexOf(direction) + 1) % sortDirections.length]
          : 'asc'
      ]
    });
  }, [config]);

  /**
   *
   */
  const updateConfig = useCallback((updates: Partial<AudioPlayerConfig>) =>
    setConfig({...config, ...updates})
  , [config]);

  /**
   *
   */
  const renderPlaylists = () =>
    <div className="ui list">
      {playlists.map(playlist =>
        <div className="item" key={playlist.id}>
          {playlist.name}
        </div>
      )}
    </div>;

  /**
   * [renderPlaylist description]
   */
  const renderPlaylist = () =>
    <div className={classNames(styles.playlist, {minimized: config.minimized})}>
      <div className="ui segments">
        {/* Header bar / controls */}
        <div className={`${styles.titleBar} ui secondary segment`}>
          <div className={classNames(
            'ui',
            {'middle aligned': useMediaQuery(tabletsAndUp)},
            'justified',
            commonStyles.autosized,
            commonStyles.nonWrapping,
            'grid',
          )}>
            {/* Player controls */}
            <div
              className={classNames(
                'middle',
                'center',
                'growing',
                'aligned',
                'column'
              )}
            >
              <Player
                disabled={!currentSong}
                song={currentSong}
                songLoading={songsLoading}
                plyrOptions={plyrOptions}
                onSongEnded={goToNextSong}
                onSeekBack={previousSong && goToPreviousSong}
                onSeekForward={nextSong && goToNextSong}
                hide={{volume: config.minimized, image: config.minimized, songInfo: config.minimized}}
                plyrEvents={{
                  playing: () => setShowWaveform(true),
                  pause: () => setShowWaveform(false)
                }}
              />
              {config.minimized}
            </div>

            {/* Action buttons */}
            <div className={classNames(
              styles.buttons,
              'middle',
              'aligned',
              commonStyles.nonShrinking,
              {'center aligned': useMediaQuery(tabletsAndUp)},
              'column'
            )}>
              <MediaQuery minWidth={600}>
                {config.minimized ?
                  <i className="ui expand alternate link icon" onClick={() => maximize()} />
                :
                  <i className="ui compress alternate link icon" onClick={() => minimize()} />
                }
              </MediaQuery>
              <i className="ui close link icon" onClick={() => close()} />
            </div>
          </div>
        </div>

        {/* Song queue */}
        {config.minimized ?
          null
        :
          isEmpty(queue) ?
            songsLoading ?
              <div className="ui placeholer loading segment">
                <br />
                <br />
                <br />
              </div>
            :
              <div className="ui placeholder segment">
                <div className="ui icon header">
                  <i className="music icon" />
                  No songs added yet.
                </div>
              </div>
          :
            <div className={classNames(
              'ui',
              styles.songListContainer,
              'segment',
              {loading: songsLoading}
            )}>
              <table className="ui small very basic sortable selectable fluid unstackable table">
                <thead>
                  <tr>
                    <th onClick={_e => sortSongs('attributes.title')}>Song</th>
                    <th>By</th>
                    <th onClick={_e => sortSongs('attributes.duration')}>Time</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {sortedSongs.map(song =>
                    <tr
                      className={classNames({current: song === currentSong})}
                      onClick={partial(setCurrentSong, song)}
                      key={song.attributes.handle}
                    >
                      <td>
                        {song.attributes.title}
                      </td>
                      <td>
                        <ResourceLinks<Contribution>
                          resources={song.relationships.contributions}
                          getText={contribution => contribution.attributes.contributor_name}
                        />
                      </td>
                      <td>{formatPlayTime(song.attributes.duration, 1)}</td>
                      <td className="collapsing">
                        <i className="ui grey x icon link" onClick={() => removeSong(song)} />
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
        }
      </div>
    </div>;

  /**
   * [renderButton description]
   */
  const renderButton = () =>
    <div className={classNames(styles.button, { showWaveform })}>
      <button
        className={classNames('ui large circular red icon button')}
        onClick={() => open()}
      >
        <i className="ui music icon" />
        <WaveformLoader className="waveform" style={{width: '1em', height: '1em'}} />
      </button>
    </div>;

  return <AudioPlayerConfigContext.Provider value={[config, updateConfig]}>
    <div className={classNames(styles.root, {open: config.open})}>
      {renderPlaylist()}
      {renderButton()}
      {addButtons.map(button =>
        <AddButton
          key={button.dataset.songHandle}
          songHandle={button.dataset.songHandle as string}
          iconOnly={button.dataset.iconOnly !== undefined}
          inline={button.dataset.inline !== undefined}
          element={button}
        />
      )}
    </div>
  </AudioPlayerConfigContext.Provider>;
};

export default AudioPlayer;
