import { Song } from 'common/models/song';
import { useCallback, useContext } from 'react';
import { useMemo } from 'use-memo-one';
import { ary, compact, filter, get, partial, zipObject } from 'lodash';
import { SongsContext } from 'audio-player/components/SongsProvider';

/**
 * [addSong description]
 * @param  songId [description]
 * @return        [description]
 */
export interface SongManager {
  readonly songs: readonly Song[];
  readonly songHandles: readonly string[];
  readonly lookup: Readonly<Record<string, Song>>;
  readonly songsLoading: boolean;

  lookupSongs(...handles: string[]): readonly Song[];

  addSong(songHandle: string): Promise<void>;
  addSong(song: Song): Promise<void>;

  removeSong(songHandle: string): Promise<void>;
  removeSong(song: Song): Promise<void>;

  hasSong(songHandle: string): boolean;
  hasSong(song: Song): boolean;
}

/**
 * [useSongs description]
 */
export default (_initialValue: string[] = []): SongManager => {
  const {
    songs,
    songHandles,
    setSongHandles,
    songsLoading,
  } = useContext(SongsContext);

  const lookup = useMemo(
    () => zipObject(songHandles, songs),
    [songHandles, songs]
  );

  /**
   *
   */
  const lookupSongs = useCallback((...handles: string[]) =>
    compact(handles.map(ary(partial(get, lookup), 1)))
  , [lookup]);

  /**
   *
   */
  const addSong = useCallback(async (songOrSongHandle: Song | string) => {
    const songHandle = typeof songOrSongHandle === 'object'
      ? songOrSongHandle.attributes.handle
      : songOrSongHandle;

    setSongHandles([...songHandles, songHandle]);
  }, [songHandles]);

  /**
   *
   */
  const removeSong = useCallback(async (songOrSongHandle: Song | string) => {
    const songHandle = typeof songOrSongHandle === 'object'
      ? songOrSongHandle.attributes.handle
      : songOrSongHandle;

    setSongHandles([...filter(songHandles, item => item !== songHandle)]);
  }, [songHandles]);

  /**
   *
   */
  const hasSong = useCallback((songOrSongHandle: Song | string) => {
    const songHandle = typeof songOrSongHandle === 'object'
      ? songOrSongHandle.attributes.handle
      : songOrSongHandle;

    return !!lookup[songHandle];
  }, [lookup]);

  return useMemo(() => ({
    songs,
    songHandles,
    lookup,
    songsLoading,
    lookupSongs,
    addSong,
    removeSong,
    hasSong,
  }), [songs, songHandles, lookup, songsLoading, lookupSongs, addSong, removeSong, hasSong]);
};
