import { parallel } from 'common/utils/async';
import { Song } from 'common/models/song';
import { client, TransformedApiResponse } from './base';

/**
 * [async description]
 * @param  handle [description]
 * @return        [description]
 */
export const getSong = async (handle: string): Promise<Song> => {
  const { data } = await client.get<TransformedApiResponse<Song>>(
    `/songs/${handle}.json`
  );

  return data.data;
};

/**
 *
 * @param handles
 */
export const getSongs = (handles: string[]): Promise<Song[]> =>
  parallel(handles, getSong);
