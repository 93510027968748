import { makeStyles } from '@material-ui/styles';
import { some } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { Button, Header, Icon, Image, List, Segment } from 'semantic-ui-react';
import { useMemo } from 'use-memo-one';
import { Search, SearchResponse } from './Search';

/**
 *
 */
export interface SidebarSearchPropTypes {
  readonly sidebar: string;
  readonly container: string;
  readonly endpoint: string;
  readonly limit: number;
  readonly transparent?: boolean;
  readonly mobile?: boolean
  readonly onResultsOpen: () => void;
}

const $ = jQuery;

/**
 *
 */
const useStyles = makeStyles({
  root: {
    padding: '1rem'
  },

  list: {
    marginTop: '3rem !important',
    marginBottom: '3rem !important',
  },

  songTitle: {
    fontSize: '1.20em'
  }
}, {
  classNamePrefix: 'SidebarSearch'
});

/**
 *
 * @param props
 * @returns
 */
export const SidebarSearch: React.FC<SidebarSearchPropTypes> = props => {
  const [ results, setResults ] = useState<SearchResponse | null>(null);
  const [ queryUrl, setQueryUrl ] = useState('');
  const [ searching, setSearching ] = useState(false);
  const [ value, setValue ] = useState<string | undefined>(undefined);

  const styles = useStyles();

  const sidebarEl = useMemo(() => document.querySelector(props.sidebar), [props.sidebar]);

  /**
   *
   */
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  const onResults = useCallback((results: SearchResponse, queryUrl: string) => {
    setResults(results);
    setQueryUrl(queryUrl);
    return false;
  }, [props.sidebar]);

  // Adjust the top-margin of the results container for the sticky header
  useEffect(() => {
    const $sidebar = $(props.sidebar);
    const $header = $('body').children('.fixed.sticky');

    $sidebar.find(props.container).css({marginTop: $header.outerHeight()});
  }, [props.sidebar, props.container]);

  useEffect(() => {
    if (!results) {
      $(props.sidebar).sidebar('hide');
      return;
    }

    const $header = $('body').children('.fixed.sticky');
    $header.addClass('search-open').removeClass('fixed');

    const $sidebar = $(props.sidebar);

    if (results?.data) $sidebar.sidebar('show');
  }, [props.container, props.sidebar, results]);


  /**
   *
   */
  const hide = useCallback(() => {
    const $header = $('body').children('.sticky');
    $header.removeClass('search-open').addClass('fixed');

    setResults(null);
    setSearching(false);
    setValue('');
  }, []);

  /**
   *
   */
  useEffect(() => {
    $(props.sidebar).sidebar({ onHide: hide, exclusive: true });

  }, [props.sidebar]);

  /**
   *
   * @returns
   */
  const renderResults = () =>
    <Segment className={styles.root} basic loading={searching}>
      <Header>Search Results</Header>
      {results && some(results.data) ?
        <>
          <List className={styles.list} relaxed="very">
            {results.data.map(song =>
              <List.Item key={song.attributes.handle}>
                <Image avatar src={song.relationships.image.links.file_thumb} />
                <List.Content>
                  <List.Header
                    as="a"
                    className={styles.songTitle}
                    onClick={() => location.href = song.links.self}
                  >
                    {song.attributes.title}
                  </List.Header>
                  <List.Description>{song.attributes.year}</List.Description>
                </List.Content>
              </List.Item>
            )}
          </List>
          {!results?.meta.pagination?.last_page &&
            <Button
              fluid
              onClick={() => location.href = queryUrl}
            >
              See more results
            </Button>
          }
        </>
      : results ?
        <Segment placeholder basic>
          <Header icon>
            <Icon name="search" />
            No results
          </Header>
        </Segment>
      :
        null
      }
    </Segment>;

  return <>
    {sidebarEl && createPortal(
      renderResults(),
      sidebarEl
    )}
    <Search
      endpoint={props.endpoint}
      transparent={props.transparent}
      limit={props.limit}
      onResults={onResults}
      onSearching={setSearching}
      onFocus={() => setValue(undefined)}
      value={value}
    />
  </>;
};
