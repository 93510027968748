/**
 *
 */
export const phonesOnly = {maxWidth: 600} as const;

/**
 *
 */
export const tabletsOnly = {minWidth: 601, maxWidth: 900} as const;
export const tabletsAndBelow = {maxWidth: 900} as const;
export const tabletsAndUp = {minWidth: 601} as const;

/**
 *
 */
export const smallMonitorsOnly = {minWidth: 901, maxWidth: 1200} as const;
export const smallMonitorsAndBelow = {maxWidth: 1200} as const;
export const smallMonitorsAndUp = {minWidth: 901} as const;

/**
 *
 */
export const largeMonitorsOnly = {minWidth: 1201} as const;
