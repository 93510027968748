import axios from 'axios';
import { entries, identity } from 'lodash';

/**
 *
 */
export interface ApiRecord {
  readonly id: string;
  readonly type?: string;
  readonly attributes: Readonly<Record<string, unknown>>;
  readonly links: Readonly<Record<string, string>>;
  readonly relationships: {
    [key: string]: ApiRecord | ApiRecord[] | ApiRelationship | ApiRelationship[] | {
      data: ApiRelationship | ReadonlyArray<ApiRelationship>
    };
  };
}

/**
 *
 */
type ApiRelationship = Omit<
  ApiRecord,
  'attributes' | 'relationships'
>;

/**
 * [create description]
 * @return [description]
 */
export interface ApiResponse {
  readonly data: ApiRecord | ReadonlyArray<ApiRecord>;

  readonly included?: ReadonlyArray<ApiRecord>;

  readonly meta: {
    readonly pagination?: {
      readonly count: number;
      readonly first_page: boolean;
      readonly last_page: boolean;
      readonly page: number;
      readonly per: number;
      readonly total_pages: number;
    };
  };
}

/**
 *
 */
export interface CollectionApiResponse extends ApiResponse {
  readonly data: ReadonlyArray<ApiRecord>;
}

/**
 *
 */
export interface SingularApiResponse extends ApiResponse {
  readonly data: ApiRecord;
}

/**
 *
 */
export type TransformedApiResponse<T> = ApiResponse & {
  readonly data: T;
}

/**
 *
 * @param response
 * @param record
 * @param name
 * @param relationship
 */
const findIncludedRecord = (
  response: ApiResponse,
  relationship: ApiRelationship
) => response.included?.find(record =>
  record.type === relationship.type && record.id === relationship.id
);

/**
 *
 * @param response
 * @param record
 */
const resolveReferences = (response: ApiResponse, record: ApiRecord) => {
  for (const [ name, relationship ] of entries(record.relationships)) {
    // Avoid circular references (which would make relationship null)
    if (!relationship) continue;
    if ('attributes' in relationship) continue;

    let references: readonly ApiRelationship[];
    let singular = true;

    if ('data' in relationship && relationship.data) {
      if ('id' in relationship.data) {
        references = [relationship.data];
      } else {
        references = relationship.data;
        singular = false;
      }
    } else if ('links' in relationship) {
      references = [relationship];
    }

    references ||= [];

    const referencedRecords = references.map(reference =>
      'id'in reference ? findIncludedRecord(response, reference) : reference
    ).filter(isDefined);

    // Recursively resolve records
    referencedRecords.forEach(
      reference => isApiRecord(reference) && resolveReferences(response, reference)
    );

    record.relationships[name] = singular ?
      (referencedRecords[0] || null) : referencedRecords;
  }
};

/**
 *
 * @param value
 * @returns
 */
const isApiRecord = (value: ApiRelationship | ApiRecord): value is ApiRecord =>
  'relationships' in value;

/**
 *
 * @param value
 * @returns
 */
const isDefined = <T>(value: T | null | undefined): value is T => !!value;

/**
 *
 * @param data
 * @returns
 */
const isCollection = (data: unknown): data is ReadonlyArray<ApiRecord> =>
  Array.isArray(data);

/**
 *
 * @param response
 */
export const handleResponse = <
  T
>(response: ApiResponse): TransformedApiResponse<T> => {
  const { data } = response;

  if (isCollection(data)) {
    data.forEach(record => resolveReferences(response, record));
  } else {
    resolveReferences(response, data);
  }

  return response as TransformedApiResponse<T>;
};

/**
 *
 * @param args
 */
export const fetchApi = async <
  T
>(...args: Parameters<typeof fetch>): Promise<TransformedApiResponse<T>> => {
  const response = await fetch(...args);
  return handleResponse(await response.json());
};

const defaultTransform = axios.defaults.transformResponse || identity;

export const client = axios.create({
  /**
   *
   * @param response
   */
  transformResponse: [
    ...(Array.isArray(defaultTransform) ? defaultTransform : [defaultTransform]),
    handleResponse,
  ]
});
