import { defaults } from 'lodash';
import Plyr from 'plyr';
import { useState, useCallback } from 'react';

/**
 *
 */
export interface PlyrHTML5 extends Plyr {
  media: HTMLAudioElement;
}

/**
 *
 */
export type RefSetter = (audio: HTMLAudioElement) => void;

/**
 *
 * @param plyr
 * @returns
 */
export const isHTML5 = (plyr: Plyr | PlyrHTML5): plyr is PlyrHTML5 =>
  'media' in plyr;

const STANDARD_EVENTS = [
  'progress',
  'playing',
  'play',
  'pause',
  'timeupdate',
  'volumechange',
  'seeking',
  'seeked',
  'ratechange',
  'ended',
  'enterfullscreen',
  'exitfullscreen',
  'captionsenabled',
  'captionsdisabled',
  'languagechange',
  'controlshidden',
  'controlsshown',
  'ready',
] as const;

/**
 *
 * @param ref
 * @param options
 * @returns
 */
export const usePlyr = (options?: Plyr.Options): readonly [ PlyrHTML5 | null, RefSetter ] => {
  const [ plyr, setPlyr ] = useState<PlyrHTML5 | null>(null);
  const [ , setTimestamp ] = useState(0);

  /**
   *
   */
  const forceUpdate = useCallback(() => setTimestamp(Date.now()), []);

  const ref = useCallback((audio: HTMLAudioElement) => {
    if (!audio) {
      return;
    }

    const newPlyr = new Plyr(
      audio,
      defaults({}, options, {
        controls: [
          'progress',
          'current-time',
          'duration',
          'mute',
          'volume',
          'settings',
          'download'
        ]
      })
    );

    STANDARD_EVENTS.forEach(event => newPlyr.on(event, _e => forceUpdate()));

    // newPlyr.on('timeupdate', () => forceUpdate());

    setPlyr(newPlyr as PlyrHTML5);
  }, [options]);

  return [ plyr, ref ] as const;
};
