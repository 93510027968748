import { Song } from 'common/models/song';
import produce from 'immer';
import { defaults, merge, remove } from 'lodash';
import { useEffect } from 'react';
import { useMemo } from 'use-memo-one';
import createPersistedState from 'use-persisted-state';
import useSongs from './use-songs';

/**
 *
 */
interface CachedPlaylist {
  readonly id: number;
  readonly name: string;
  readonly createdAt: number;
  readonly handles: readonly string[];
}

/**
 *
 */
interface CreatePlaylistOptions {
  name: string;
  handles?: string[];
}

/**
 *
 */
export interface Playlist extends CachedPlaylist {
  readonly songs: readonly Song[];
}

/**
 *
 */
export interface PlaylistsManager {
  readonly playlists: readonly Playlist[];

  /**
   *
   * @param song
   */
  updatePlaylist(playlistOrId: Playlist | number, updates: Partial<Playlist>): void;

  /**
   *
   * @param playlist
   */
  createPlaylist(playlist: CreatePlaylistOptions): void;

  /**
   *
   * @param playlist
   */
  deletePlaylist(playlistOrId: Playlist | number): void;
}

const useCachedPlaylistsState = createPersistedState('playlists');

export const usePlaylists = (): PlaylistsManager => {
  const [ cachedPlaylists, setCachedPlaylists ] = useCachedPlaylistsState<CachedPlaylist[]>([]);
  const { lookupSongs } = useSongs();

  const playlists = useMemo(() =>
    cachedPlaylists.map(playlist => ({
      ...playlist,
      songs: lookupSongs(...playlist.handles)
    }))
  , [cachedPlaylists, lookupSongs]);

  return {
    playlists,

    /**
     *
     */
    updatePlaylist(playlistOrId: Playlist | number, updates: Partial<Playlist>) {
      const id = typeof playlistOrId === 'number'
        ? playlistOrId
        : playlistOrId.id;

      setCachedPlaylists(produce(cachedPlaylists, draft => {
        const playlist = draft.find(playlist => playlist.id === id);
        merge(playlist, updates);
      }));
    },

    /**
     *
     */
    createPlaylist(options: CreatePlaylistOptions) {
      setCachedPlaylists(produce(cachedPlaylists, draft => {
        const now = Date.now();

        const playlist = {
          ...defaults(options, {handles: []}),
          id: now,
          createdAt: now
        };

        draft.push(playlist);
      }));
    },

    /**
     *
     */
    deletePlaylist(playlistOrId: Playlist | number) {
      const id = typeof playlistOrId === 'number'
        ? playlistOrId
        : playlistOrId.id;

      setCachedPlaylists(produce(cachedPlaylists, draft => {
        remove(draft, playlist => playlist.id === id);
      }));
    },
  };
};
