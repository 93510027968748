import { makeStyles } from '@material-ui/styles';
import useEvent from 'audio-player/hooks/use-event';
import classNames from 'classnames';
import React, { useState } from 'react';
import { Progress } from 'semantic-ui-react';

const useStyles = makeStyles({
  root: {
    margin: '0 !important',
    position: 'absolute !important' as 'absolute',
    right: 0,
    left: 0,
    zIndex: 10,
    background: 'none !important',

    '&:not(.active)': {
      display: 'none !important'
    },

    '& > .bar': {
      background: 'none !important',
      margin: '0 !important',
    }
  }
}, {
  classNamePrefix: 'NavProgress',
});

/**
 *
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface NavProgressPropTypes {}


/**
 *
 * @param props
 */
export const NavProgress: React.FC<NavProgressPropTypes> = props => {
  const [ active, setActive ] = useState(false);

  const styles = useStyles();

  useEvent('turbolinks:click', document, () => {
    setActive(true);
  });

  useEvent('turbolinks:load', document, () => {
    setActive(false);
  });

  return <Progress
    size="tiny"
    color="red"
    label={false}
    total={1}
    value={1}
    className={classNames('fast indeterminate swinging', { active }, styles.root)}
  />;
};
