import React, { useEffect, useRef, useState } from 'react';

/**
 *
 */
export interface ScriptureSliderPropTypes {
  readonly delay: number;
  readonly scriptures: ReadonlyArray<{
    text: string;
    reference: string;
  }>;
}

/**
 *
 * @param props
 * @returns
 */
export const ScriptureSlider: React.FC<ScriptureSliderPropTypes> = props => {
  const { delay } = props;

  const [ scriptures, setScriptures ] = useState(props.scriptures || []);
  const [ index, setIndex ] = useState(-1);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const loadScriptures = async () => {
      const scriptures = await jQuery.get('/scriptures.json');
      setScriptures(scriptures);
      setIndex(0);
    };

    if (scriptures.length === 0) {
      loadScriptures();
    } else {
      setIndex(0);
    }
  }, []);

  useEffect(() => {
    if (!wrapperRef.current || !scriptures.length) return;

    const timeout = setTimeout(() =>
      setIndex(oldIndex => (oldIndex + 1) % scriptures.length),
      props.delay || 5000
    );

    return () => clearTimeout(timeout);
  }, [delay, scriptures, wrapperRef, index]);

  useEffect(() => {
    if (!wrapperRef.current) return;

    const $wrapper = jQuery(wrapperRef.current);

    const animate = async () => {
      await $wrapper.find('.scripture.visible')
        .removeClass('visible')
        .fadeOut()
        .promise();

      $wrapper.find(`.scripture[data-index=${index}]`)
        .addClass('visible')
        .fadeIn();
    };

    animate();
  }, [index]);

  return <div className="scripture-carousel" ref={wrapperRef}>
    {scriptures.map((scripture, i) =>
      <div data-index={i} className="scripture" style={{display: 'none'}} key={i}>
        &quot;{scripture.text}&quot; &mdash; {scripture.reference}
      </div>
    )}
  </div>;
};
