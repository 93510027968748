import React from 'react';
import classNames from 'classnames';
import Plyr, { PlyrEvent, PlyrEventMap, PlyrStateChangeEvent } from 'plyr';
import { useCallback, useEffect, useMemo, useRef, useState, MouseEvent } from 'react';
import { Song } from 'common/models/song';
import _, { assign, each, first, round } from 'lodash';
import { useMediaQuery } from 'react-responsive';
import { formatPlayTime } from 'audio-player/lib/time';
import { isHTML5, usePlyr } from 'audio-player/hooks/use-plyr';
import { RepeatMode, usePlayerConfig } from 'audio-player/hooks/use-player-config';
import { useStyles } from './styles/Player';
import { Slider } from './inputs/Slider';
import { Button, Icon, Progress } from 'semantic-ui-react';
import { smallMonitorsAndBelow } from './styles/responsive';
import { isMobile } from 'react-device-detect';

/**
 *
 */
type PlyrEventCallback = (this: Plyr, e: PlyrEvent | PlyrStateChangeEvent) => void;

/**
 * [Audio description]
 * @type {[type]}
 */
export interface PropTypes {
  readonly song?: Song;

  readonly small?: boolean;

  readonly disabled?: boolean;

  readonly waveformId?: string;

  readonly songLoading?: boolean;

  readonly plyrOptions?: Plyr.Options;

  readonly plyrEvents?: Partial<Record<keyof PlyrEventMap, PlyrEventCallback>>;

  readonly hide?: Partial<Record<'volume' | 'image' | 'progress' | 'songInfo' | 'buttons' | 'all', boolean>>;

  /**
   * @param [config]
   * @returns the next song
   */
  readonly onSongEnded?: () => Song | undefined;

  /**
   *
   */
  readonly onSongChange?: () => void;

  /**
   *
   */
  readonly onSeekBack?: () => void;

  /**
   *
   */
  readonly onSeekForward?: () => void;


  /**
   *
   */
  readonly onPlayingChanged?: (playing: boolean) => void;
}

/**
 *
 */
class Lock {
  private lock?: number;

  /**
   *
   */
  obtain() {
    return this.lock = Date.now();
  }

  /**
   *
   */
  release() {
    this.lock = undefined;
  }

  /**
   *
   * @returns
   */
  getLock() {
    return this.lock;
  }
}

const transitionLock = new Lock();

/**
 * [Plyr description]
 * @type {[type]}
 */
const Player: React.FC<PropTypes> = props => {
  const ref = useRef<HTMLDivElement>(null);

  const [ initializing, setInitializing ] = useState(true);
  const [ timesVisible, setTimesVisible ] = useState(false);

  const [ plyr, audioRef ] = usePlyr(props.plyrOptions);
  const config = usePlayerConfig();
  const styles = useStyles();

  const small = useMediaQuery(smallMonitorsAndBelow);

  /**
   *
   */
  // eslint-disable-next-line @typescript-eslint/no-inferrable-types
  const changeSong = useCallback((song?: Song, lockId?: number) => {
    if (!plyr || transitionLock.getLock() !== lockId) return;

    const { playing } = plyr;

    plyr.source = {
      type: 'audio',
      sources: [{
        src: song?.relationships.music_attachment.links.file || '',
      }],
    };

    plyr.media.title = song?.attributes.title || '';

    plyr.togglePlay(playing && !!song);
  }, [plyr]);

  /**
   * Set the source and update the ID of the audio element for the waveform
   */
  useEffect(
    () => changeSong(props.song),
    [
      // Only update the source when the source link changes. This allows the
      // song itself to be updated
      props.song?.relationships.music_attachment.links.file,
      changeSong,
    ]
  );

  /**
   *
   */
  useEffect(() => {
    if (!plyr) return;

    /**
     *
     * @param e
     */
    const onVolumeChange = (_e: Plyr.PlyrEvent) => config.volume = plyr.volume;
    plyr.on('volumechange', onVolumeChange);

    /**
     *
     * @param _e
     */
    const onTimeUpdate =  (_e: Plyr.PlyrEvent) => {
      if (!initializing) config.currentTime = plyr.currentTime;
    };
    plyr.on('timeupdate', onTimeUpdate);



    /**
     *
     * @param e
     */
    const onEnded = (_e: Plyr.PlyrEvent) => {
      transitionLock.obtain();
      const nextSong = props.onSongEnded?.();

      if (!nextSong) return;

      plyr.media.src = nextSong.relationships.music_attachment.links.file;
      plyr.media.title = nextSong?.attributes.title || '';

      // Play the next song, because if a song "ended" we are still in play
      // mode
      plyr.play();
    };
    plyr.on('ended', onEnded);

    /**
     *
     * @param e
     */
    const onPlaying = (_e: Plyr.PlyrEvent) => {
      transitionLock.release();
      console.log('playing');
    };

    plyr.on('playing', onPlaying);

    /**
     *
     * @param e
     */
    const onLoadedMetadata = (_e: Plyr.PlyrEvent) => {
      if (plyr.duration) plyr.currentTime = 0;
    };
    plyr.on('loadedmetadata', onLoadedMetadata);

    return () => {
      plyr.off('volumechange', onVolumeChange);
      plyr.off('timeupdate', onTimeUpdate);
      plyr.off('ended', onEnded);
      plyr.off('playing', onPlaying);
      plyr.off('canplay', onLoadedMetadata);
    };
  }, [plyr, config, props.song, props.onSongEnded, initializing]);

  useEffect(() => {
    if (!plyr || !props.plyrEvents) return;

    each(
      props.plyrEvents,
      (callback: PlyrEventCallback, event: keyof PlyrEventMap) =>
        plyr.on(event as keyof PlyrEventMap, callback.bind(plyr))
    );

    return () => {
      each(
        props.plyrEvents,
        (callback: PlyrEventCallback, event: keyof PlyrEventMap) =>
          plyr.off(event as keyof PlyrEventMap, callback.bind(plyr))
      );
    };
  }, [plyr, props.plyrEvents]);

  /**
   * Initialization...
   */
  useEffect(() => {
    if (!plyr || !plyr.duration || !initializing) return;
    setInitializing(false);

    plyr.volume = config.volume;
    plyr.currentTime = config.currentTime || 0;
  }, [plyr, plyr?.duration, config, initializing]);

  /**
   *
   */
  const seekBack = useCallback(() => {
    if (!plyr) return;

    if (plyr.currentTime <= 1) {
      props.onSeekBack?.();
    } else {
      plyr.restart();
    }
  }, [plyr, props.onSeekBack]);

  const seekForward = useCallback(() => {
    if (!plyr) return;

    const nextSong = props.onSeekForward?.();

    // Causes the song to restart playing if the user seeks forward and the
    // next song is the same as the current. If we don't do this, then the
    // player won't restart because the song file doesn't change.
    if (nextSong === props.song) plyr.restart();
  }, [plyr, props?.onSeekForward]);

  /**
   *
   */
  const advanceRepeat = useCallback(() =>
    config.repeat = RepeatMode[RepeatMode[config.repeat + 1] as keyof typeof RepeatMode] || RepeatMode.None
  , [config]);

  /**
   *
   */
  const onProgressClick = useCallback((e: MouseEvent<HTMLDivElement>) => {
    if (!plyr) return;

    const x = e.clientX - e.currentTarget.getBoundingClientRect().x;
    const ratio = x / e.currentTarget.clientWidth;

    plyr.currentTime = ratio * plyr.duration;
  }, [plyr]);

  /**
   *
   */
  const setVolume = useCallback((volume: number) => {
    if (!plyr) return;

    config.volume = plyr.volume = volume;
  }, [plyr, config]);

  const currentTime = useMemo(() =>
    plyr ? formatPlayTime(round(plyr.currentTime)) : null,
    [plyr?.currentTime]
  );

  const remainingTime = useMemo(() =>
    plyr ? formatPlayTime(round(plyr.duration - plyr.currentTime)) : null,
    [plyr?.currentTime, plyr?.duration]
  );

  return <>
    {/* Invisible audio element */}
    <div style={{display: 'none'}}>
      <audio preload="metadata" crossOrigin="anonymous" controls={true} ref={audioRef}></audio>
    </div>

    <div
      ref={ref}
      className={classNames(
        {disabled: props.disabled, hidden: props.hide?.all},
        styles.root,
      )}
    >
      {/* Control buttons */}
      <div className={classNames(
        styles.buttons,
        'ui',
        'center',
        'aligned',
        'basic',
        'tertiary',
        'icon',
        'buttons'
      )}>
        <Button
          icon={<Icon size="small" color={config.shuffled ? 'red' : undefined} name="shuffle" />}
          basic
          className="shuffle"
          onClick={_e => config.shuffled = !config.shuffled}
        />

        <Button
          disabled={!props.onSeekBack || !props.song}
          basic
          icon={<Icon name="step backward" />}
          onClick={() => seekBack()}
        />

        <Button
          disabled={!props.song}
          basic
          icon={
            <Icon size="large" color={plyr?.playing ? 'red' : undefined} name={plyr?.playing ? 'pause' : 'play'} />
          }
          className="play"
          onClick={_e => plyr?.togglePlay()}
        />

        <Button
          disabled={!props.onSeekForward || !props.song}
          basic
          className="forward"
          icon={<Icon name="step forward" />}
          onClick={() => seekForward()}
        />

        <Button
          className="repeat"
          basic
          icon={
            <Icon
              size="small"
              name="repeat"
              color={config.repeat != RepeatMode.None ? 'red' : undefined}
              className={classNames({
                one: config.repeat === RepeatMode.One
              })}
            />
          }
          onClick={_e => advanceRepeat()}
        />
      </div>

      {!props.hide?.songInfo &&
        <div
          className={classNames(
            'ui',
            'segment',
            {loading: props.songLoading},
            {tertiary: !props.song},
            styles.currentSongInfo
          )}
          onMouseOver={_e => setTimesVisible(true)}
          onMouseOut={_e => setTimesVisible(false)}
        >
          {/* Current song info */}
          {props.song ?
            <div className={classNames('ui', 'link', 'header', {tiny: small})}>
              {!props.hide?.image &&
                <img className="ui image" src={props.song.relationships.image.links.file_thumb} />
              }
              <div className="content">
                <a href={props.song.links.self}>{props.song.attributes.title}</a>

                {props.song.relationships.contributions[0] ?
                  <div className="sub header">
                    by {props.song.relationships.contributions[0]?.attributes.contributor_name}
                  </div>
                :
                  null
                }
              </div>
            </div>
          :
            <i className="ui large grey music icon" />
          }

          {/* Song times */}
          <div className={classNames(styles.songTimes, {visible: (isMobile || timesVisible) && props.song})}>
            <span>{currentTime}</span>
            <span>{remainingTime}</span>
          </div>

          {/* Song progress bar */}
          <Progress
            disabled={!props.song}
            size="tiny"
            color={plyr?.playing ? 'red' : undefined}
            attached="bottom"
            className="song-progress"
            active={plyr?.playing}
            percent={plyr ? plyr.currentTime / plyr.duration * 100 : 0}
            onClick={onProgressClick}
          >
          </Progress>
        </div>
      }

      {/* Song volume */}
      {!props.hide?.volume &&
        <div className={classNames(styles.volume)}>
          <i className="ui volume down icon"></i>
          <Slider
            small
            smooth
            color="red"
            min={0}
            max={1}
            step={0.01}
            initialValue={config.volume}
            onChange={e => { if (plyr) setVolume(e.detail); }}
            className="volume"
          />
          <i className="ui volume up icon"></i>
        </div>
      }
    </div>
  </>;
};

export default Player;
