import { makeStyles } from '@material-ui/styles';
import { media } from '.';
import { phonesOnly, tabletsAndBelow, tabletsAndUp } from './responsive';

/**
 *
 */
export const useStyles = makeStyles({
  root: {
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%',

    [media(phonesOnly)]: {
      flexDirection: 'column',
      alignItems: 'center',
    },

    [media(tabletsAndUp)]: {
      '& > $buttons': {
        flexGrow: 0,
        marginBottom: '0 !important',
      },

      '& > $progress': {
        marginBottom: '0 !important',

        '& .slider': {
          padding: '0.5rem !important'
        },
      },

      '& > $volume': {
        '& .slider': {
          margin: '0 !important'
        },
      },
    },

    '& > *': {
      marginBottom: '1rem !important',

      '&:last-child': {
        marginBottom: '0 !important'
      }
    },

    // '&.disabled': {
    //   '&.ui.horizontal.segments': {
    //     filter: 'grayscale(1)',

    //     '& .ui.segment': {
    //       opacity: 0.5
    //     }
    //   },

    //   '&:after': {
    //     content: "''",
    //     height: '100%',
    //     position: 'absolute',
    //     width: '100%',
    //     zIndex: 10
    //   },
    // },

    '&.hidden': {
      opacity: 0,
    },
  },

  buttons: {
    border: 'none !important',
    display: 'flex',
    flexGrow: 1,
    maxWidth: '200px',

    [media(tabletsAndUp)]: {
      flexGrow: 0,
      flexShrink: 1,

      '& > .ui.button': {
        padding: '0.5rem !important',
      },
    },

    [media(phonesOnly)]: {
      marginBottom: '1.5rem !important',
      marginTop: '4rem !important',
      order: 3,
    },

    '& > .ui.button': {
      alignItems: 'center',
      border: 'none !important',
      display: 'flex',
      justifyContent: 'center',

      '&:hover, &:focus': {
        background: 'transparent !important',
        boxShadow: 'none !important'
      }
    }
  },

  progress: {
    alignItems: 'center',
    display: 'flex',

    [media(phonesOnly)]: {
      order: 2,
    },

    [media(tabletsAndBelow)]: {
      width: '100%'
    },
  },

  volume: {
    alignItems: 'center',
    display: 'flex',
    minWidth: '100px',

    [media(phonesOnly)]: {
      order: 4,
      width: '100%',

      '& > .slider': {
        padding: '0 !important'
      },
    },

    '& > .slider': {
      margin: '0 1em !important',

      [media(tabletsAndBelow)]: {
        margin: '0 !important',
      }
    },
  },

  break: {
    flexBasis: '100%',
    height: 0,
  },

  currentSongInfo: {
    borderRadius: '0 !important',
    flexGrow: 1,
    margin: '0 1rem !important',
    position: 'relative',
    whiteSpace: 'nowrap',

    [media(phonesOnly)]: {
      alignSelf: 'stretch',
      margin: '0 !important',
      order: 1,
      pading: '1rem !important'
    },

    '&:not(.tertiary):after': {
      background: 'linear-gradient(270deg, white, transparent)',
      content: '""',
      height: '100%',
      position: 'absolute',
      right: '1em',
      top: 0,
      width: '30%',
      zIndex: 1,
    },

    '&.loading': {
      minHeight: '4em',
    },

    '& .song-progress.progress': {
      background: '#CCC !important',
      borderRadius: 0,
      bottom: '0 !important',
      cursor: 'pointer',
      height: '0.35em !important',
      top: 'auto !important',
      zIndex: 2,

      '&.disabled': {
        cursor: 'default'
      },

      [media(phonesOnly)]: {
        bottom: '-2rem !important'
      },

      '& .bar': {
        height: 'inherit',
        minWidth: '0 !important',
      },
    },

    '& .ui.header': {
      margin: '0 !important',
      overflow: 'hidden',

      '& .image': {
        margin: '0 !important'
      }
    },
  },

  songTimes: {
    bottom: '-1.5em',
    display: 'flex',
    fontSize: '0.75em',
    justifyContent: 'space-between',
    left: '0',
    opacity: 0,
    position: 'absolute',
    right: '0',
    transition: 'opacity 0.25s',

    [media(phonesOnly)]: {
      bottom: '-3.5rem !important'
    },

    '&.visible': {
      opacity: 1,
      transition: 'opacity 0.25s',
    }
  },
}, {
  classNamePrefix: 'Player'
});
