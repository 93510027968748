import React from 'react';
import AudioPlayer from './AudioPlayer';
import { SongsProvider } from './SongsProvider';

/**
 *
 * @param _props
 * @returns
 */
export const Root: React.FC = _props =>
  <SongsProvider>
    <AudioPlayer></AudioPlayer>
  </SongsProvider>;
