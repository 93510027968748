import { makeStyles } from '@material-ui/styles';

/**
 *
 */
export const useCommonStyles = makeStyles({
  autosized: {
    '&.grid > .column': {
      width: 'auto !important'
    }
  },

  nonShrinking: {
    '&.column': {
      flexShrink: '0'
    }
  },

  nonWrapping: {
    '&.grid': {
      flexWrap: 'nowrap'
    }
  },
}, {
  classNamePrefix: 'Common'
});
