import React, { Key, ReactElement } from 'react';

/**
 *
 */
export interface ResourceLinksPropTypes<T> {
  readonly resources: readonly T[];

  /**
   *
   */
  readonly getLink?: (resource: T) => string | undefined;

  /**
   *
   */
  readonly getText: (resource: T) => string;

  /**
   *
   */
  readonly getKey?: (resource: T) => Key;
}


/**
 *
 * @param props
 */
export const ResourceLinks = <T extends unknown>(
  props: ResourceLinksPropTypes<T>
): ReactElement<unknown> => {

  return <>
    {props.resources.map((resource, i) =>
      <span key={props.getKey?.(resource) || i}>
        {typeof props.getLink?.(resource) !== 'undefined' ?
          <a

            href={props.getLink?.(resource)}
          >
            {props.getText(resource)}
          </a>
        :
          props.getText(resource)
        }
        {i < props.resources.length - 1 ? ', ' : null }
      </span>
    )}
  </>;
};
